import { DatePipe } from '@angular/common';
import { DataTypeEnum } from '../../data-type.enum';
import { CellClickedEvent, ValueSetterParams, ValueFormatterParams,
         ICellRendererParams, GridOptions, GetContextMenuItemsParams, GridApi } from '@ag-grid-enterprise/all-modules';
import { CodeListService } from 'src/app/shared/code-list.service';
import { CodeListElement } from '../model/code-list-element-model';
import { HCurrencyPipe } from 'src/app/shared/hcurrency.pipe';
import { CompanyService } from 'src/app/shared/company.service';
import { CompanyViewModel } from 'src/app/setting/company/company-view.model';
import { Report } from '../model/report.model';
import { InlineAutocompleteComponent } from '../../inline-autocomplete.component';
import { InlineNumberComponent } from '../../inline-number.component';
import { Globals } from '../../globals';
import { NumberRange } from '../model/number-range-model';
import { Dimensions } from '../model/dimensions-model';
import { MulticurrencyPrice } from '../model/multicurrency-price-model';
import { LocalizedTexts } from '../model/localized-texts-model';
import { InlineDatepickerComponent } from '../../inline-datepicker.component';
import { DateRange } from '../../date-range.model';
import { MultiLang } from '../../multi-lang/multi-lang.model';
import { MultiLangEditorComponent } from '../../multi-lang/multi-lang-editor.component';
import { TranslationService } from 'src/app/shared/translation.service';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class ReportGridSetup {
    public codeListMap: { [key: number]: CodeListElement } = {};
    public company: CompanyViewModel;
    protected currencyColumnsCache = {};

    constructor(@Inject(TranslationService) private trans: TranslationService,
                @Inject(CodeListService) private codeListsService: CodeListService,
                private datePipe: DatePipe,
                private hcurrency: HCurrencyPipe,
                private globals: Globals,
                private copmanyService: CompanyService) {

        let storedCodelist = sessionStorage['Honeycomb.ReportSetupCodeLists' + this.globals.getTenantID()];
        if (!!storedCodelist) {
            this.codeListMap = JSON.parse(storedCodelist);
        } else {
            this.codeListsService.list([]).subscribe(list => {
                list.forEach(cl => {
                    let elem = <CodeListElement>{
                        codeListID: cl.codeListId,
                        name: cl.codeListName,
                        values: {}
                    };
                    cl.codes.forEach(c => {
                        elem.values[c.codeKey] = c.codeValue;
                    });
                    this.codeListMap[cl.codeListId] = elem;
                });
                sessionStorage['Honeycomb.ReportSetupCodeLists' + this.globals.getTenantID()] = JSON.stringify(this.codeListMap);
            });
        }
        this.copmanyService.getActive().subscribe( c => {
            this.company = c;
        });
    }

    public getCodeListKeys( id: number ) {
        if (this.codeListMap[id]) {
            return Object.keys(this.codeListMap[id].values);
        }
        return [];
    }

    public getCodeListRefData( id: number ) {
        if (this.codeListMap[id]) {
            return this.codeListMap[id].values;
        }
        return {};
    }

    public getDefaultOptions(): GridOptions {
        return <GridOptions> {
            enableRangeSelection: true,
            animateRows: true,
            singleClickEdit: true,
            rowSelection: 'multiple',
            rowGroupPanelShow : 'always',
            suppressCopyRowsToClipboard: true,
            stopEditingWhenCellsLoseFocus: true,
            defaultColDef : {
                enableValue: true,
                enableRowGroup: true,
                enablePivot: true,
                sortable: true,
                resizable: true,
                minWidth: 150 // necessary!!! for the automatically created columns, etc.
            },
            columnTypes: this.getColumnTypes(),
            localeTextFunc: (key, defaultValue) => {
                let tKey = 'admin.web.rep.grid.' + key;
                let translated = this.trans.instant(tKey);
                if (translated === tKey) {
                    return defaultValue;
                }
                return translated;
            },
            getContextMenuItems: (params: GetContextMenuItemsParams) => {
                return this.getContextMenuItems( undefined, params );
            },
            frameworkComponents : {
                inlineAutoComplete: InlineAutocompleteComponent,
                inlineNumberComponent: InlineNumberComponent,
                inlineDatepickerComponent: InlineDatepickerComponent,
                multiLangEditor: MultiLangEditorComponent
            }
        };
    }

    public getColumnTypes() {
        return {
            'editable': { editable: true },
            'numeric': {
                filter: 'agNumberColumnFilter', cellClass: 'report-grid-numeric-cell',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 100,
                minWidth: 100,
                cellEditor: 'inlineNumberComponent',
                cellEditorParams: {
                }
            },
            'money' : {
                filter: 'agNumberColumnFilter', cellClass: 'report-grid-numeric-cell',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                aggFunc: 'sum',
                width: 100,
                minWidth: 100,
                valueFormatter: (params) => {
                    let p = <ValueFormatterParams>params;
                    if (!p.value) {
                        return '';
                    }
                    let isoCode = 'czk';
                    if (this.company) {
                        isoCode = this.company.currencyIsoCode;
                    }
                    if (p.data) {
                        if (this.currencyColumnsCache[p.colDef.field]) {
                            isoCode = p.data[this.currencyColumnsCache[p.colDef.field]];
                        } else  {
                            // check for the currency column definition and cache the currency column name when found
                            let report = <Report>p.context;
                            if (report) {
                                let rc = report.reportColumns.find( c => c.uniqueColumnName === p.colDef.field);
                                if (rc.currencyQueryableColumnID) {
                                    let currencyCol = report.reportColumns.find( c => c.queryableColumnID === rc.currencyQueryableColumnID);
                                    if (currencyCol) {
                                        isoCode = p.data[currencyCol.uniqueColumnName];
                                        this.currencyColumnsCache[p.colDef.field] = currencyCol.uniqueColumnName;
                                    }
                                }
                            }
                        }
                    }
                    return this.hcurrency.transform(Number(p.value), isoCode);
                },
                cellEditor: 'inlineNumberComponent',
                cellEditorParams: {
                }
            },
            'image': {
                width: 160,
                minWidth: 160,
                cellRenderer: (params) => {
                    let p = <ICellRendererParams>params;
                    if (!p.value) {
                        return '';
                    }
                    return '<a><image src="' + p.value + '" height="' + p.node.rowHeight + '"></a> &nbsp;';
                }
            },
            'date': {
                filter: 'agDateColumnFilter', cellClass: 'report-grid-date-cell',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                valueFormatter: (params) => {
                    return this.datePipe.transform(<Date>params.value, 'medium', null, null );
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'inlineDatepickerComponent'
            },
            'short-date': {
                filter: 'agDateColumnFilter', cellClass: 'report-grid-date-cell',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                valueFormatter: (params) => {
                    return this.datePipe.transform(<Date>params.value, 'mediumDate', null, null );
                },
                width: 120,
                minWidth: 120,
                cellEditor: 'inlineDatepickerComponent'
            },
            'text': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor',
                cellEditorParams: {
                    cols: 90,
                    rows: 15,
                    maxLength: 65565,
                }
            },
            'codelist': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agRichSelectCellEditor'
            },
            'localizationKey': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                valueFormatter: (params) => {
                    return this.trans.instant( params.value );
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor'
            },
            'detail': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                cellClass: 'report-grid-detail-cell',
                cellEditor: 'agTextCellEditor'
            },
            'boolean': {
                filter: 'agNumberColumnFilter',
                cellClass: 'report-grid-boolean-cell',
                editable: false,
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 90,
                minWidth: 60,
                cellEditor: 'agTextCellEditor',
                cellRenderer: params => {
                    return params.value ? this.checkedIcon : this.uncheckedIcon;
                }
            },
            'boolean-editable': {
                filter: 'agNumberColumnFilter',
                cellClass: 'report-grid-boolean-cell',
                editable: false,
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 90,
                minWidth: 60,
                cellEditor: 'agTextCellEditor',
                cellRenderer: params => {
                    return params.value ? this.checkedIcon : this.uncheckedIcon;
                },
                onCellClicked: event => {
                    let e = <CellClickedEvent>event;
                    e.data[e.colDef.field] = !e.value;
                    if (typeof e.colDef.valueSetter === 'function') {
                        let params = <ValueSetterParams>{
                            node: e.node,
                            data: e.data,
                            colDef: e.colDef,
                            column: e.column,
                            api: e.api,
                            columnApi: e.columnApi,
                            context: e.context,
                            newValue: e.data[e.colDef.field],
                            oldValue: e.value
                        };
                        e.colDef.valueSetter(params);
                    }
                    e.api.refreshCells();
                }
            },
            'array-of-dates': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor',
                valueFormatter: (params: ValueFormatterParams) => {
                    let obj = params.value;
                    if (typeof obj === 'string' || obj instanceof String) {
                        obj = JSON.parse(<string>obj);
                    }
                    if (!obj || !Array.isArray(obj)) {
                        return params.value;
                    }
                    return obj.map( d => this.datePipe.transform(<Date>d, 'mediumDate', null, null )).join(',');
                }
            },
            'array-of-numbers': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor',
                valueFormatter: (params: ValueFormatterParams) => {
                    let obj = params.value;
                    if (typeof obj === 'string' || obj instanceof String) {
                        obj = JSON.parse(<string>obj);
                    }

                    if (!obj || !Array.isArray(obj)) {
                        return params.value;
                    }
                    return obj.join(',');
                }
            },
            'array-of-strings': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor',
                valueFormatter: (params: ValueFormatterParams) => {
                    let obj = params.value;
                    if (typeof obj === 'string' || obj instanceof String) {
                        obj = JSON.parse(<string>obj);
                    }

                    if (!obj || !Array.isArray(obj)) {
                        return params.value;
                    }
                    return obj.join(',');
                }
            },
            'number-range': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor',
                valueFormatter: (params: ValueFormatterParams) => {
                    let obj = params.value;
                    if (typeof obj === 'string' || obj instanceof String) {
                        obj = JSON.parse(<string>obj);
                    }

                    if (!obj || !(typeof obj === 'object')) {
                        return params.value;
                    }
                    let nr = <NumberRange>obj;
                    return nr.From.toString() + ' - ' + nr.To.toString();
                }
            },
            'date-range': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor',
                valueFormatter: (params: ValueFormatterParams) => {
                    let obj = params.value;
                    if (typeof obj === 'string' || obj instanceof String) {
                        obj = JSON.parse(<string>obj);
                    }

                    if (!obj || !(typeof obj === 'object')) {
                        return params.value;
                    }
                    let dr = <DateRange>obj;
                    return this.datePipe.transform(<Date>dr.since, 'mediumDate', null, null ).toString() + ' - '
                         + this.datePipe.transform(<Date>dr.till, 'mediumDate', null, null ).toString();
                }
            },
            'dimensions': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor',
                valueFormatter: (params: ValueFormatterParams) => {
                    let obj = params.value;
                    if (typeof obj === 'string' || obj instanceof String) {
                        obj = JSON.parse(<string>obj);
                    }

                    if (!obj || !(typeof obj === 'object')) {
                        return params.value;
                    }
                    let d = <Dimensions>obj;
                    return d.WidthCM + 'cm x ' + d.HeightCM + 'cm x ' + d.DepthCM + 'cm';
                }
            },
            'localized-texts': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor',
                valueFormatter: (params: ValueFormatterParams) => {
                    let obj = params.value;
                    if (typeof obj === 'string' || obj instanceof String) {
                        obj = JSON.parse(<string>obj);
                    }

                    if (!obj || !(typeof obj === 'object')) {
                        return params.value;
                    }
                    let lt = <LocalizedTexts>obj;
                    let res = '';
                    Object.keys( lt.Texts ).forEach( k => {
                        res += k + ': ' + lt.Texts[k];
                    });
                    return res;
                }
            },
            'multicurrency-price': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'agTextCellEditor',
                valueFormatter: (params: ValueFormatterParams) => {
                    let obj = params.value;
                    if (typeof obj === 'string' || obj instanceof String) {
                        obj = JSON.parse(<string>obj);
                    }

                    if (!obj || !(typeof obj === 'object')) {
                        return params.value;
                    }
                    let mp = <MulticurrencyPrice>obj;
                    let res = '';
                    Object.keys( mp.Prices ).forEach( k => {
                        res += k + ': ' + mp.Prices[k];
                    });
                    return res;
                }
            },
            'multi-lang': {
                filter: 'agTextColumnFilter',
                filterParams: {
                    applyButton: true,
                    clearButton: true,
                    suppressAndOrCondition: true,
                    newRowsAction: 'keep'
                },
                width: 150,
                minWidth: 150,
                cellEditor: 'multiLangEditor',
                valueFormatter: (params: ValueFormatterParams) => MultiLang.defaultText(params.value)
            }
        };
    }

    private checkedIcon = '<mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">check</mat-icon>';
    private uncheckedIcon = '<mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">crop_din</mat-icon>';

    public getColumnType(dataType: DataTypeEnum): string {
        switch (dataType) {
            case DataTypeEnum.DateTime:
                return 'date';

            case DataTypeEnum.ShortDate:
                return 'short-date';

            case DataTypeEnum.Number:
            case DataTypeEnum.IntegerNumber:
                return 'numeric';

            case DataTypeEnum.Money:
                return 'money';

            case DataTypeEnum.Boolean:
                return 'boolean';

            case DataTypeEnum.Image:
                return 'image';

            case DataTypeEnum.LocalizationKey:
                return 'localizationKey';

            case DataTypeEnum.CodeList:
                return 'codelist';

            case DataTypeEnum.ArrayOfDates:
                return 'array-of-dates';

            case DataTypeEnum.ArrayOfNumbers:
                return 'array-of-numbers';

            case DataTypeEnum.ArrayOfStrings:
                return 'array-of-strings';

            case DataTypeEnum.NumberRange:
                return 'number-range';

            case DataTypeEnum.DateRange:
                return 'date-range';

            case DataTypeEnum.Dimensions:
                return 'dimensions';

            case DataTypeEnum.LocalizedTexts:
                return 'localized-texts';

            case DataTypeEnum.MulticurrencyPrice:
                return 'multicurrency-price';

            case DataTypeEnum.MultiLang:
                return 'multi-lang';

            case DataTypeEnum.Text:
            default:
                return 'text';
        }
    }

    public getColumnTypeClasses( colTypes: any ): string {
        let types = this.getColumnTypes();
        let ct = [];
        if (!Array.isArray( colTypes )) {
            ct = colTypes.split(',');
            ct.forEach(t => t.trim());
        } else {
            ct = colTypes;
        }
        let classes = [];
        ct.forEach( t => {
            let colDef = types[t];
            if (colDef && colDef.cellClass) {
                classes.push( colDef.cellClass );
            }
        });
        return classes.join(',');
    }

    public getContextMenuItems(deleteCallback: Function = undefined, params: GetContextMenuItemsParams = undefined): any[] {
        let res: any[] = [
            'autoSizeAll',
            'copy',
            'copyWithHeaders',
            'paste',
            'resetColumns'
        ];
        if (deleteCallback) {
            res.push({
                name: this.trans.instant('admin.web.remove'),
                action: deleteCallback
            });
        }
        res.push({
            name: this.trans.instant('admin.web.export' ) + ' XLS',
            action: () => this.excelExportCallback( params.api, 'xlsx' )
        }, {
            name: this.trans.instant('admin.web.export' )  + ' XML',
            action: () => this.excelExportCallback( params.api, 'xml' )
        });
        return res;
    }

    private excelExportCallback( api: GridApi, format ) {
        api.exportDataAsExcel({
            exportMode: format,
            processCellCallback: (p) => {
                const colDef = p.column.getColDef();
                // try to reuse valueFormatter from the colDef
                if (colDef.valueFormatter && (typeof colDef.valueFormatter === 'function' && colDef.valueFormatter.name) ) {
                    const valueFormatterParams: ValueFormatterParams = {
                        ...p,
                        data: p.node.data,
                        node: p.node!,
                        colDef: p.column.getColDef()
                    };
                    return colDef.valueFormatter(valueFormatterParams);
                } else if (colDef.type === 'codelist' && colDef.refData != null)  {
                    return colDef.refData[p.value];
                }
                return p.value;
            },
        });
    }

    public allRecordsSize = 10000000;

    public getPageSize(): any[] {
        return [
            { value: 100, text: '100' },
            { value: 1000, text: '1 000' },
            { value: 5000, text: '5 000' },
            { value: 10000, text: '10 000' },
            { value: 100000, text: '100 000' },
            { value: this.allRecordsSize, text: this.trans.instant('admin.web.rep.allRecords') }
        ];
    }
}
