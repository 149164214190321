import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { BuildConfigService } from "./build-config.service";

export function MSALInstanceFactory(buildConfig: BuildConfigService ) : IPublicClientApplication {

    if (!buildConfig.initialized) {
        return new PublicClientApplication({auth: { clientId: null }});    
    }

    return new PublicClientApplication({
        auth: {
            clientId: buildConfig.azureLogin.clientID,
            redirectUri: buildConfig.azureLogin.redirectUri,
            postLogoutRedirectUri: buildConfig.azureLogin.postLogoutRedirectUri,
        },
        system: {
            loadFrameTimeout: 30000,
            navigateFrameWait: 30000
        }
    });
}