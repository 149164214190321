import { CategoryTexts } from './category/category.model';

// tslint:disable: no-use-before-declare
export class CategoryTreeModel {
    public root: CategoryListNodeModel;
    public nodes: CategoryListNodeModel[];
    public nodesList: any[];

    constructor() {
        this.root = new CategoryListNodeModel();
        this.nodes = new Array<CategoryListNodeModel>();
        this.nodesList = [];
    }
}

// tslint:disable-next-line: no-use-before-declare
export class CategoryListNodeModel implements ITreeNode {

    private _children: Array<CategoryListNodeModel>;
    private _value: string;
    public id: any;
    public caption: string = null;
    public categoryId: any;
    public categoryCode: string = null;
    public categoryTexts: CategoryTexts[];
    public published = true;
    public showOptions: boolean;
    public inEdit: boolean;
    public imageGuid: string = null;
    public imageUrl: string = null;
    public dbKey: string = null;
    public isSelected = false;
    public categoryData: CategoryListNodeModel;

    public parentCategoryId: number = null;
    public parentPathLevel: number = null;
    public parentBinaryPath: string = null;
    public parentStringPath: string = null;

    public categoryNode: CategoryListNodeModel = null;

    public pathLevel: number = null;
    public binaryPath: string = null;
    public stringPath: string = null;

    public productsActive: number = null;
    public ProductsAll: number = null;

    public partners: CategoryMenuNodePartners = new CategoryMenuNodePartners();
    public items: Array<CategoryListNodeModel>;
    public tempImageGuid: string = null;
    public origImageGuid: string = null;

    public static buildFromTreeNodes(treeNodes: Array<CategoryListNodeModel>, filterID: string): Array<CategoryListNodeModel> {
        let res = new Array<CategoryListNodeModel>();
        treeNodes.forEach( c => {
            res.push(c);
        });
        return res;
    }

    constructor(source: any = null) {
        if (source !== null) {
            this.categoryId = source.categoryId;
            this.imageGuid = source.imageGuid;
            this.categoryCode = source.categoryCode;

            this.productsActive = source.productsActive;
            this.ProductsAll = source.ProductsAll;

            let catText = source.categoryTexts.find(ct => ct.languageIsocode === source.targetLanguage);

            let texts = (!!catText) ? catText : source.categoryTexts[0];
            this.categoryTexts = source.categoryTexts;

            this.published = source.published;

            this.caption = texts ? texts.shortName : '';

            let counts = ' (' + (source.ProductsActive || 0) + '/' + (source.ProductsAll || 0) + ')';
            this.caption += counts;

            this.items = [];
            this.partners = new CategoryMenuNodePartners();
            this.dbKey = source.dbKey;
            this.isSelected = false;
            this.categoryData = source;

            // if (!!source.items) {
            //     this.items = source.items.map(i => new CategoryListNodeModel(i));
            // }
        }
    }

    public get children(): Array<CategoryListNodeModel> {
        return this.items;
    }

    public set children(value) {
        this.items = value;
    }

    public get value(): string {
        return this._value;
    }
    public set value(v: string) {
        this._value = v;
    }
}

export class CategoryMenuModel {
    public items: CategoryMenuNodeModel[];
    public filterID: string;
}

export class CategoryMenuNodeModel implements ITreeNode {

    private _children: Array<CategoryMenuNodeModel>;
    private _value: string;
    public id: any;
    public caption: string = null;
    public categoryId: any;
    public categoryCode: string;
    public showOptions: boolean;
    public inEdit: boolean;
    public imageGuid: string = null;
    public imageUrl: string = null;
    public dbKey: string = null;
    public isSelected = false;
    public isMarked = false;
    public categoryData: any;

    public partners: CategoryMenuNodePartners = new CategoryMenuNodePartners();
    public items: Array<CategoryMenuNodeModel>;
    public tempImageGuid: string = null;
    public origImageGuid: string = null;

    public static buildFromTreeNodes(treeNodes: Array<CategoryMenuNodeModel>, filterID: string): Array<CategoryMenuNodeModel> {
        let res = new Array<CategoryMenuNodeModel>();
        treeNodes.forEach( c => {
            res.push(c);
        });
        return res;
    }

    constructor(source: any = null) {
        if (source !== null) {
            this.categoryId = source.categoryId;
            this.id = source.categoryId; // required to support selecting / expanding programatically
            this.caption = source.categoryCode + ' - ' + source.shortName;

            let counts = ' (' + (source.ProductsActive || 0) + '/' + (source.ProductsAll || 0) + ')';
            this.caption += counts;

            this.categoryCode = source.categoryCode;
            this.imageGuid = source.imageGuid;
            this.items = [];
            this.partners = new CategoryMenuNodePartners();
            this.dbKey = source.dbKey;
            this.isSelected = false;
            this.categoryData = source;

            if (!!source.items) {
                this.items = source.items.map(i => new CategoryMenuNodeModel(i));
            }
        }
    }

    public get children(): Array<CategoryMenuNodeModel> {
        return this.items;
    }

    public set children(value) {
        this.items = value;
    }

    public get value(): string {
        return this._value;
    }
    public set value(v: string) {
        this._value = v;
    }

    public get stringifiedId(): string {
        return '' + this.id;
    }

    public get seletectedIDs(): any[] {
        let res: any[] = [];
        this.getSelectedIDs(res);
        return res;
    }

    private getSelectedIDs( ids: any[] ) {
        if (this.isSelected) {
            ids.push(this.id);
        }
        if (this.children) {
            this.children.forEach( c => {
                c.getSelectedIDs(ids);
            });
        }
    }

    public get seletectedNames(): string[] {
        let res: any[] = [];
        this.getSeletectedNames(res);
        return res;
    }

    private getSeletectedNames( names: string[] ) {
        if (this.isSelected) {
            names.push(this.caption);
            return; // for names when the parent is selected, don't include the children
        }
        if (this.children) {
            this.children.forEach( c => {
                c.getSeletectedNames(names);
            });
        }
    }

    public containsString( str: string ) {
        let containsObj = {
            contains: false
        };
        this.containsStringInt( str.toLowerCase(), containsObj );
        return containsObj.contains;
    }

    private containsStringInt( str: string, containsObj: any ) {
        if (containsObj.contains) {
            return;
        }
        if (this.caption.toLowerCase().includes( str )) {
            containsObj.contains = true;
            return;
        }
        if (this.children) {
            this.children.forEach( c => c.containsStringInt( str, containsObj ));
        }
    }

}

export class CategoryMenuNodePartners {
    public googleSection: String;
    public heurekaSection: String;
    public sClickSection: String;
}

export interface ITreeNode {
    dbKey: string;
    showOptions: boolean;
    inEdit: boolean;
    partners: CategoryMenuNodePartners;
    children: Array<any>;
}
