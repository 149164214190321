import { RouterModule,
         Routes,
         PreloadAllModules }                    from '@angular/router';
import { HomeComponent }                        from './home/home.component';
import { LoginComponent }                       from './login/login.component';
import { AuthGuard }                            from './common/auth.guard';

import { NgModule } from '@angular/core';


// tslint:disable:max-line-length
const appRoutes: Routes = [
    { path: 'setting-core', loadChildren: () => import('./setting-core/setting-core.module').then(m => m.SettingCoreModule) },
    { path: 'setting', loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule) },
    { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
    { path: 'statistics', loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule) },
    { path: 'communication', loadChildren: () => import('./communication/communication.module').then(m => m.CommunicationModule) },
    { path: 'survey', loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule) },
    { path: 'product', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
    { path: 'planner', loadChildren: () => import('./planner/planner.module').then(m => m.PlannerModule) },
    { path: 'tasker', loadChildren: () => import('./tasker/tasker.module').then(m => m.TaskerModule) },
    { path: 'order', loadChildren: () => import('./order/order.module').then(m => m.OrderModule) },
    { path: 'login', component: LoginComponent},
    { path: '', component: HomeComponent, canActivate: [AuthGuard], data: { locKey: 'admin.web.home' }}, // Keep this at 0 index, this is default Home
  ];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: false, preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

// const routes: Routes = appRoutes;

// export const routing = RouterModule.forRoot(routes, { enableTracing: true, preloadingStrategy: NoPreloading });
