import { Injectable }           from '../../../node_modules/@angular/core';
import { CommonService }        from './common.service';
import { Globals }              from '../common/globals';
import { Observable }           from '../../../node_modules/rxjs';
import { HttpClient } from '@angular/common/http';
import { AttachmentModel } from '../common/user.model';

@Injectable()
export class UserDocumentService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'user/document');
    }

    public upsertItem(item: AttachmentModel): Observable<any> {
        return this._commonService.post('/' + item.userId.toString(), item);
    }

    public deleteItem(userId: number, attachmentId: number ): Observable<any> {
        return this._commonService.delete('/' + userId.toString() + '/' + attachmentId.toString());
    }
}
