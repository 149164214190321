import { Component, Input, Optional, Host, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { SatPopover } from '@ncstate/sat-popover';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from './functions';

@Component({
  selector: 'hc-inline-select',
  styleUrls: ['inline-select.component.scss'],
  templateUrl: 'inline-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineSelectComponent implements OnInit {

  @Input()
  get value(): string { return this._value; }
  set value(x: string) {
    this.editVal = this._value = x;
  }
  private _value = '';

  @Input()
  public valueProperty: string;

  @Input()
  public displayFunction: Function = null;

  @Input()
  public dataSource: any;

  /** Form model for the input. */
  editVal = '';

  constructor(
    @Optional()
    @Host()
    private popover: SatPopover,
    private changeRef: ChangeDetectorRef) { }

  valueSelected($event: MatSelectChange) {
    this.value = $event.value;
    this.onSubmit();
  }

  ngOnInit() {
    // subscribe to cancellations and reset form value
    if (this.popover) {
      this.popover.closed.pipe(filter(val => isNullOrUndefined(val)))
          .subscribe(() => {
            this.editVal = this.value || '';
            this.changeRef.markForCheck();
          });
    }
  }

  onSubmit() {
    if (this.popover) {
      this.popover.close(this.editVal);
    }
  }

  onCancel() {
    if (this.popover) {
      this.popover.close();
    }
  }
}
