import { Component,
         Input,
         Optional,
         Host,
         OnInit,
         ChangeDetectionStrategy,
         AfterViewInit}         from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SatPopover }     from '@ncstate/sat-popover';
import { filter }         from 'rxjs/operators';

@Component({
  selector: 'hc-inline-bool',
  styleUrls: ['inline-bool.component.scss'],
  templateUrl: 'inline-bool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineBoolComponent implements OnInit, AfterViewInit {

  @Input()
  get value(): boolean { return this._value; }
  set value(x: boolean) {
    this.editVal = this._value = x;
  }
  private _value = false;

  @Input()
  caption = '';

  /** Form model for the input. */
  public editVal = false;

  constructor(@Optional() @Host() public popover: SatPopover) { }
  
  ngAfterViewInit(): void {
    this.editVal = this._value;
  }

  boolCheckedChanged(event: MatCheckboxChange) {
    this.editVal = event.checked;
  }

  ngOnInit() {
    // subscribe to cancellations and reset form value
    // if (this.popover) {
    //   this.popover.closed.pipe(filter(val => val == null))
    //     .subscribe(() => this.editVal = !!this.value);
    // }
  }

  onSubmit() {
    if (this.popover) {
      this.popover.close(this.editVal);
    }
  }

  onCancel() {
    if (this.popover) {
      this.popover.close();
    }
  }
}
