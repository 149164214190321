import { Directive, Output, Input, OnInit, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

// https://stackoverflow.com/questions/49918503/angular-6-warning-for-using-formcontrolname-and-ngmodel/50707432

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[hcValidatedNgModel]',
})
export class HcValidatedNgModelDirective implements OnInit {

    @Output() customNgModelChange: EventEmitter<any> = new EventEmitter();

    @Input() hcValidatedNgModel: any;

    @Output() hcValidatedNgModelChange: EventEmitter<any> = new EventEmitter();

    @Input() formControl: FormControl;

    ngOnInit() {
        if (this.formControl) {
            this.formControl.setValue(this.hcValidatedNgModel || null);
            this.formControl.valueChanges.subscribe(v => {
                this.customNgModelChange.emit(v);
            });
        }
    }
}
