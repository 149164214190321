import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'hc-confirm-dialog',
  template:
  `
     <div fxLayout="column" style="height: 150px; padding: 15px">
           <p fxFlex>
               {{ (message | trans ) }}
           </p>
           <div fxFlex="none"  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center">
               <button mat-raised-button color="secondary" (click)="clickNo()" >{{ 'common.no' | trans }}</button>
               <button mat-raised-button color="primary" (click)="clickYes()" >{{ 'common.yes' | trans }}</button>
           </div>
     </div>
      `
})

export class ConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

    clickYes(): void {
        this.dialogRef.close(true);
    }
    clickNo(): void {
        this.dialogRef.close(false);
    }
}
