import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, firstValueFrom, of } from "rxjs";
import { BuildConfig } from "./build-config.model";

@Injectable({
    providedIn: 'root'
})

export class BuildConfigService extends BuildConfig {

    public initialized = false;

    constructor(private http: HttpClient) {
        super();
    }

    load() {
        return firstValueFrom(this.http.get<BuildConfig>('configuration/configuration.json').pipe(catchError(e => of(false))))
            .then(
                data => {
                    if (!!data) {
                        data = <BuildConfig>data;
                        this.info = data.info || 'default';
                        if (!!data.azureLogin) {
                            this.azureLogin = {
                                enabled: data.azureLogin.enabled || false,
                                redirectUri: data.azureLogin.redirectUri || null,
                                postLogoutRedirectUri: data.azureLogin.postLogoutRedirectUri || null,
                                clientID: data.azureLogin.clientID || null,
                            }
                        }
                        if (!!data.standardLogin) {
                            this.standardLogin = {
                                enabled: data.standardLogin.enabled || false
                            }
                        }
                        this.tenantID = data.tenantID || -1;
                        this.initialized = true;
                        this.externalAuthApiUri = data.externalAuthApiUri || 'http://localhost';
                    } else {
                        console.info('Could not load configuration');
                        this.info = 'default';
                        this.standardLogin = {
                            enabled: true
                        },
                        this.azureLogin = {
                            enabled: false,
                            redirectUri: null,
                            postLogoutRedirectUri: null,
                            clientID: null,
                            authApiUri: 'http://localhost'
                        };
                        this.tenantID = -1
                        this.initialized = true;
                    }
                
            })
            .catch(() => {
                console.log('empty catch');
            })
    }

}