export abstract class BuildConfig {
    info: string;
    standardLogin?: {
        enabled: boolean
    };
    azureLogin?: {
        enabled: boolean,
        redirectUri?: string,
        postLogoutRedirectUri?: string,
        clientID?: string,
        authApiUri?: string
    };
    tenantID?: number = null;
    externalAuthApiUri?: string = "http://localhost"
}