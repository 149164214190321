import {
    Component, Input, Optional, Host, OnInit, ChangeDetectorRef,
    ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter
} from '@angular/core';
import * as moment_ from 'moment';
import { DateRange } from './date-range.model';
import { DateRangeEnum } from './date-range.enum';
import { $enum } from 'ts-enum-util';
const moment = moment_;

@Component({
    selector: 'hc-date-range-selector',
    templateUrl: 'date-range-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeSelectorComponent implements OnInit {
    public periodOptions: {};
    public periods: any[];
    public dateRangeEnum = DateRangeEnum;

    @Input()
    caption = '';

    @Input()
    required = false;

    @Input()
    isReadonly = true;

    @Input()
    hidePeriodPicker = false;

    @Input()
    public value: DateRange;

    @Output() public valueChange: EventEmitter<DateRange> = new EventEmitter();

    constructor(
        private cd: ChangeDetectorRef
    ) {
        this.periodOptions = new DateRange().getDatePeriodOptions();
        this.periods = $enum(DateRangeEnum).getValues();
    }

    ngOnInit() {

        if (!this.value) {
            this.value = new DateRange();
            this.value.period = DateRangeEnum.this_week;
            this.value.since = this.periodOptions[this.value.period].start.toDate();
            this.value.till = this.periodOptions[this.value.period].end.toDate();
        }

        if (this.valueChange) {
            this.valueChange.emit(this.value);
        }
        this.cd.markForCheck();
    }

    public setPeriod() {
    }

    public get isLocked(): boolean {
        return !this.periodOptions[this.value.period]?.unlock;
    }

    public onSelectionChanged($event) {
        if (this.isLocked) {
            if (!this.periodOptions[$event.value]) { //not defined option, cleanup
                this.value.since = null;
                this.value.till = null;
            }else{
                this.value.since = this.periodOptions[$event.value].start.toDate();
                this.value.till = this.periodOptions[$event.value].end.toDate();
            }
        }
        if (this.valueChange) {
            this.valueChange.emit(this.value);
        }
    }

    public onDateChanged($event) {
        if (this.valueChange) {
            this.valueChange.emit(this.value);
        }
    }
}

