import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputDialogContent } from './dialog-input-content.model';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'hc-input-dialog',
  template:
  `
       <div fxLayout="column" style="padding: 15px">
           <h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
           <div mat-dialog-content fxLayout="column">
               <p>{{ (data.message | trans ) }}</p>
               <mat-form-field fxFlex="1 0 100%">
                    <label>{{ data.inputName | trans }}</label>
                   <input matInput [(ngModel)]="data.inputContent" type="data.inputType">
               </mat-form-field>
           </div>
           <div mat-dialog-actions fxFlex="1 0 100%" fxLayout="row" fxLayoutAlign="end center">
               <button mat-raised-button (click)="clickCancel()" >{{ 'admin.web.cancel' | trans }}</button>
               <button mat-raised-button color="primary" (click)="clickOk()" cdkFocusInitial>{{ 'admin.web.ok' | trans }}</button>
           </div>
       </div>
      `
})

export class InputDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputDialogContent
    ) { }

    clickOk(): void {
        this.dialogRef.close(true);
    }
    clickCancel(): void {
        this.dialogRef.close(false);
    }
}
