import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslationService } from '../../shared/translation.service';
import { SortableFilter, SortDirection } from '../../common/sortable-filter';
import { ClubService } from '../../shared/club.service';
import { ClubType } from './club-type.enum';
import { ClubCreationDataModel } from './club-creation-data-model';
import { CreateClubOnTempTableRequest } from 'src/app/common/club-on-temptable-request-model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectChange } from '@angular/material/select';

enum ResponseState {
    CREATED,
    NOT_CREATED,
    SAVED,
    NOT_SAVED,
    ALREADY_EXISTS
}

@Component({
    selector: 'hc-create-club-dialog',
    templateUrl: './create-club.dialog.component.html',
    styleUrls: []
})
export class CreateClubDialogComponent implements OnInit {

    private trans: TranslationService = null;
    public nameFormControl = new FormControl('', [Validators.required]);
    public inProgress = false;
    public isError = false;
    public isFromFilter = false;
    public clubs = [];
    public clubSelectControl = new FormControl(this.clubs[0]/*this.getSavedFileFormat()*/, [Validators.required]);
    public newClub = false;

    constructor(
        private dialogRef: MatDialogRef<CreateClubDialogComponent>,
        trans: TranslationService,
        private service: ClubService,
        private snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: ClubCreationDataModel
    ) {
        this.trans = trans;
        this.clubs = [
            { description: this.trans.instant('admin.web.create-new-club'), value: -2 },
        ];
    }

    ngOnInit() {
        this.inProgress = true;
        let filter = new SortableFilter('Name', SortDirection.asc);
        filter.pageSize = 1000;
        this.service.select(filter).subscribe(res => {
            res.items.forEach(club => {
                this.clubs.push({
                    description: club.type === ClubType.System ? this.trans.instant(club.name) : club.name,
                    value: club.clubID
                });
            });
            this.inProgress = false;
        });
    }

    public confirm() {
        this.inProgress = true;
        if (this.isNameInputVisible()) {
            (this.data.createTempTable) ? this.createFromTempTable() : this.create();
        } else {
            (this.data.createTempTable) ? this.addToExistingFromTempTable() : this.addToExisting();
        }
    }

    public create() {
        this.service.create(this.nameFormControl.value, this.data.rowIDs)
            .subscribe(
                res => this.onRespone(ResponseState.CREATED),
                err => this.onRespone(err.includes("already exists") ? ResponseState.ALREADY_EXISTS : ResponseState.NOT_CREATED),                 
                () => this.dialogRef.close()
            );
    }

    public createFromTempTable() {
        if (this.data.existingIDsTableName) {
            this.service.createClubOnTempTable( <CreateClubOnTempTableRequest>{
                name: this.nameFormControl.value,
                tempTableName: this.data.existingIDsTableName,
                idColumnName: this.data.existingIDsColumnName,
                deleteTable: false
            }).subscribe(
                    res => this.onRespone(ResponseState.CREATED),
                    err => this.onRespone(err.includes("already exists") ? ResponseState.ALREADY_EXISTS : ResponseState.NOT_CREATED),                 
                    () => this.dialogRef.close()
                );
        } else {
            this.data.createTempTableDatasource.createIDsTempTable().subscribe( r => {
                this.service.createClubOnTempTable( <CreateClubOnTempTableRequest>{
                    name: this.nameFormControl.value,
                    tempTableName: r.tempTableName,
                    idColumnName: r.idColumnName,
                    deleteTable: true
                }).subscribe(
                        res => this.onRespone(ResponseState.CREATED),
                        err => this.onRespone(err.includes("already exists") ? ResponseState.ALREADY_EXISTS : ResponseState.NOT_CREATED),                                        
                        () => this.dialogRef.close()
                    );
            });
        }
    }

    public addToExisting() {
        this.service.addToWhiteList(this.clubSelectControl.value.value, this.data.rowIDs)
            .subscribe(
                res => this.onRespone(ResponseState.SAVED),
                err => {this.onRespone(err === "The club new already exists" ? ResponseState.ALREADY_EXISTS : ResponseState.NOT_CREATED);
                            console.log(err === "The club new already exists")},
                () => this.dialogRef.close()
            );
    }

    public addToExistingFromTempTable() {
        if (this.data.existingIDsTableName) {
            this.service.addToWhiteListOnTempTable( <CreateClubOnTempTableRequest>{
                clubID: this.clubSelectControl.value.value,
                tempTableName: this.data.existingIDsTableName,
                idColumnName: this.data.existingIDsColumnName,
                deleteTable: false
            }).subscribe(
                    res => this.onRespone(ResponseState.SAVED),
                    err => this.onRespone(err.includes("already exists") ? ResponseState.ALREADY_EXISTS : ResponseState.NOT_CREATED),             
                    () => this.dialogRef.close()
                );
        } else {
            this.data.createTempTableDatasource.createIDsTempTable().subscribe( r => {
                this.service.addToWhiteListOnTempTable( <CreateClubOnTempTableRequest>{
                    clubID: this.clubSelectControl.value.value,
                    tempTableName: r.tempTableName,
                    idColumnName: r.idColumnName,
                    deleteTable: true
                }).subscribe(
                        res => this.onRespone(ResponseState.SAVED),
                        err => this.onRespone(err.includes("already exists") ? ResponseState.ALREADY_EXISTS : ResponseState.NOT_CREATED),                 
                        () => this.dialogRef.close()
                    );
            });
        }
    }

    onClubSelected(event: MatSelectChange): void 
    {
        this.newClub = event.value.value === -2 ? true : false; // -2 is new club creation
    }

    private onRespone(responseState: ResponseState) {
        this.inProgress = false;
        let message: string;
        switch  (responseState) {
            case ResponseState.CREATED:
                message = 'admin.web.list-created';
                this.isError = false;
                this.newClub = false;
                break;
            case ResponseState.NOT_CREATED:
                message = 'admin.web.list-create-error';
                this.isError = true;
                this.newClub = false;
                break;
            case ResponseState.SAVED:
                message = 'admin.web.saved';
                this.isError = false;
                this.newClub = false;
                break;
            case ResponseState.NOT_SAVED:
                message = 'admin.web.error-saving';
                this.isError = true;
                this.newClub = false;
                break;
            case ResponseState.ALREADY_EXISTS:
                message = 'admin.web.error-saving';
                this.isError = true;
                this.newClub = true;
                break;
        }
        if (!this.isError) {
            this.service.mediaClubsUpdated.emit(this.clubSelectControl.value.value);
        }
        this.snackbar.open(this.trans.instant(message), '', {duration: 2000});
    }

    public close() {
        this.dialogRef.close();
    }

    public isValid(countError?: boolean): boolean {
        return !this.isNameInputVisible() || !this.nameFormControl.hasError('required');
    }

    public isNameInputVisible(): boolean {
        return this.clubSelectControl.value === this.clubs[0];
    }
}

